import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 896.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,896.000000) scale(0.100000,-0.100000)" >


<path d="M5120 7784 c-36 -2 -134 -11 -219 -19 -612 -61 -1215 -309 -1706
-701 -110 -88 -345 -316 -422 -409 -102 -124 -139 -173 -213 -284 -438 -652
-597 -1446 -445 -2206 166 -825 684 -1554 1425 -2003 407 -246 811 -387 1301
-453 131 -18 214 -23 414 -23 254 -1 368 8 585 45 802 137 1536 573 2013 1197
332 435 524 894 599 1436 29 209 29 539 0 746 -36 258 -108 528 -199 745 -253
607 -718 1135 -1303 1477 -338 198 -703 332 -1095 402 -210 37 -550 60 -735
50z m-332 -1371 c-158 -2 -419 -2 -580 0 -161 1 -32 2 287 2 319 0 451 -1 293
-2z m-541 -35 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m2133 -2
c139 -18 360 -59 402 -75 l27 -10 3 -333 c2 -183 2 -334 2 -336 -1 -1 -23 32
-49 75 -245 396 -522 624 -807 663 -83 12 -180 8 -317 -11 -171 -25 -359 -178
-395 -322 -32 -131 -24 -274 21 -367 41 -84 313 -352 443 -436 25 -16 47 -32
50 -35 3 -4 37 -24 75 -45 39 -22 78 -43 86 -49 14 -8 19 -46 29 -218 7 -114
11 -209 9 -212 -5 -4 -112 47 -133 64 -6 4 -40 23 -76 42 -36 19 -69 38 -75
42 -5 5 -34 23 -64 40 -78 46 -159 99 -191 124 -8 7 -44 33 -80 59 -36 26
-115 97 -175 158 -139 140 -180 213 -197 354 -13 100 -3 221 27 335 56 213
224 380 460 458 164 54 208 58 515 55 182 -3 330 -10 410 -20z m-1164 5 c-4
-5 0 -11 6 -14 7 -2 1 -4 -14 -4 -21 0 -24 2 -15 13 14 15 31 19 23 5z m-63
-8 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-886 -25 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-20 -50 c-3 -7 -5 -2 -5 12 0 14
2 19 5 13 2 -7 2 -19 0 -25z m-326 -95 c-8 -15 -10 -15 -11 -2 0 17 10 32 18
25 2 -3 -1 -13 -7 -23z m1744 17 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6
8 10 10 10 2 0 7 -4 10 -10z m-30 -30 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10
0 6 4 10 9 10 6 0 13 -4 16 -10z m68 -14 c4 -10 1 -13 -9 -9 -15 6 -19 23 -5
23 5 0 11 -7 14 -14z m-105 -61 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10
-15 7 -18z m217 15 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0
13 -4 16 -10z m-180 -21 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17
-11z m224 -13 c9 -11 9 -16 -1 -22 -7 -4 -10 -4 -6 1 4 4 3 14 -3 22 -6 7 -9
13 -6 13 2 0 10 -6 16 -14z m46 -36 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6
2 10 4 10 3 0 8 -4 11 -10z m-231 -16 c5 -14 4 -15 -9 -4 -17 14 -19 20 -6 20
5 0 12 -7 15 -16z m203 -9 c8 -9 13 -18 11 -21 -3 -2 -13 5 -23 16 -21 23 -11
27 12 5z m78 -15 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0
13 -4 16 -10z m-121 -136 c9 -24 7 -28 -9 -14 -17 14 -20 30 -6 30 5 0 12 -7
15 -16z m342 -131 c-3 -4 -6 -1 -6 6 0 7 -4 9 -10 6 -6 -4 -7 1 -3 11 6 15 7
15 15 -1 6 -9 7 -19 4 -22z m-296 -3 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
6 -1 10 4 10 6 0 11 -4 11 -10z m33 -73 c-3 -5 -11 4 -19 18 -20 38 -17 45 5
15 11 -13 17 -28 14 -33z m301 28 c9 -14 13 -25 8 -25 -5 0 -14 11 -20 25 -6
14 -10 25 -8 25 2 0 11 -11 20 -25z m45 -75 c11 -24 11 -24 -3 -6 -9 11 -16
24 -16 30 0 12 5 7 19 -24z m32 -89 c-8 -5 -10 -14 -6 -22 6 -10 5 -11 -3 -4
-6 6 -9 21 -5 33 4 17 9 20 17 12 8 -8 7 -13 -3 -19z m-304 -73 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m392 -128 c0 -10 -3 -8 -9 5 -12 27
-12 43 0 25 5 -8 9 -22 9 -30z m-334 -60 c-4 -17 -9 -30 -11 -27 -2 2 -1 18 2
36 4 17 9 30 11 27 3 -2 2 -19 -2 -36z m28 -10 c-3 -12 -8 -19 -11 -16 -5 6 5
36 12 36 2 0 2 -9 -1 -20z m344 -102 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7
2 -19 0 -25z m-340 -40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m330 -50 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m-350 10
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m380 -40 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-2190 -250 c-1 -46 -3 -9 -3 82 0 91
2 128 3 83 2 -46 2 -120 0 -165z m2180 140 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m10 -80 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m0 -120 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m246
-137 c151 -160 212 -309 223 -541 5 -96 2 -130 -15 -205 -34 -143 -89 -260
-170 -366 -44 -56 -147 -146 -197 -172 -22 -11 -41 -23 -44 -26 -8 -11 -192
-79 -255 -95 -105 -26 -143 -27 -470 -17 -332 11 -494 29 -721 81 l-92 21 -7
242 c-3 133 -5 285 -3 337 l3 95 22 -40 c83 -151 249 -360 372 -467 109 -96
227 -167 340 -208 50 -17 81 -20 237 -20 251 0 342 25 462 127 104 89 171 194
202 319 19 75 22 106 17 213 -4 127 -21 198 -72 304 -15 33 -15 38 5 96 11 33
27 120 36 193 8 73 17 152 20 176 7 53 16 49 107 -47z m-616 47 c-3 -7 -5 -2
-5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m0 -80 c-3 -7 -5 -2 -5 12 0 14 2 19
5 13 2 -7 2 -19 0 -25z m350 -30 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-340 -50 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m360 -50 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-360 -25 c-3
-10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-30 -5 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m380 -10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m-370 -60 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2
-28 0 -40z m360 10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-390 -50 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m20 -30 c-3
-7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m20 -15 c-3 -10 -5 -2 -5 17
0 19 2 27 5 18 2 -10 2 -26 0 -35z m287 -89 c3 -8 2 -12 -4 -9 -6 3 -10 10
-10 16 0 14 7 11 14 -7z m-374 -71 c-11 -17 -11 -17 -6 0 3 10 6 24 7 30 0 9
2 9 5 0 3 -7 0 -20 -6 -30z m321 -66 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3
-3 0 -11 -7 -18z m-307 -23 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11
14 -7z m253 -16 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-301
-24 c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z
m324 -4 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m-359 -77 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m270 -6
c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m39 2 c-7 -11 -14
-18 -17 -15 -3 3 0 12 7 21 18 21 23 19 10 -6z m-314 -96 c-10 -10 -19 5 -10
18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m214 -47 c0 -2 -8 -10 -17 -17 -16 -13
-17 -12 -4 4 13 16 21 21 21 13z m-300 -51 c14 -19 14 -19 -2 -6 -10 7 -18 18
-18 24 0 6 1 8 3 6 1 -2 9 -13 17 -24z m-39 -38 c-10 -9 -11 -8 -5 6 3 10 9
15 12 12 3 -3 0 -11 -7 -18z m279 19 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4
0 6 5 11 10 11 6 0 10 -2 10 -4z m-30 -30 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1
-10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-82 -43 c-10 -2 -18 -9 -18 -15 0 -6
-3 -9 -6 -5 -10 10 6 27 24 26 15 -1 15 -2 0 -6z m-198 -34 c-7 -11 -14 -18
-17 -15 -3 3 0 12 7 21 18 21 23 19 10 -6z m220 18 c0 -2 -12 -14 -27 -28
l-28 -24 24 28 c23 25 31 32 31 24z m-100 -61 c0 -2 -7 -7 -16 -10 -8 -3 -12
-2 -9 4 6 10 25 14 25 6z m-199 -39 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3
0 -11 -7 -18z m-38 -2 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z
m227 0 c-7 -9 -15 -13 -19 -10 -3 3 1 10 9 15 21 14 24 12 10 -5z m-100 1 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-170
-51 c-7 -8 -16 -15 -21 -15 -5 0 -1 8 9 18 20 22 31 19 12 -3z m136 -40 c-21
-16 -32 -13 -21 4 3 6 14 11 23 11 15 -1 15 -2 -2 -15z m-1819 -27 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-24 -43 c-3 -9 -8 -14 -10 -11 -3
3 -2 9 2 15 9 16 15 13 8 -4z m-49 -60 c-4 -8 -11 -15 -16 -15 -6 0 -5 6 2 15
7 8 14 15 16 15 2 0 1 -7 -2 -15z m-34 -35 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m35 -9 c-3 -6 -11 -11 -17 -11 -6 0
-6 6 2 15 14 17 26 13 15 -4z m-65 -31 c-10 -6 12 -10 70 -13 l85 -3 -94 -2
c-82 -2 -92 0 -81 13 7 8 18 15 24 15 6 0 4 -5 -4 -10z m77 4 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m398 -5 c11 -16 1 -19 -13 -3 -7 8 -8
14 -3 14 5 0 13 -5 16 -11z m-520 -9 c-3 -5 -14 -10 -23 -10 -15 0 -15 2 -2
10 20 13 33 13 25 0z m413 -7 c-27 -2 -69 -2 -95 0 -27 2 -5 3 47 3 52 0 74
-1 48 -3z m445 0 c-51 -2 -135 -2 -185 0 -51 1 -10 3 92 3 102 0 143 -2 93 -3z
m324 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m131 -1 c-16
-2 -40 -2 -55 0 -16 2 -3 4 27 4 30 0 43 -2 28 -4z m-1295 -10 c-7 -2 -19 -2
-25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m604 1 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z m276 -1 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0
35 -2 23 -4z m795 -139 c19 -13 21 -18 11 -30 -9 -12 -25 -13 -65 -8 -28 3
-65 3 -82 -1 -27 -6 -30 -10 -34 -51 l-3 -45 77 2 c43 2 82 -1 88 -7 13 -13
-4 -37 -27 -39 -10 -1 -46 -5 -80 -9 l-63 -8 0 -49 0 -49 80 0 c66 0 82 -3 87
-16 10 -26 -25 -44 -84 -44 -69 0 -120 21 -128 52 -4 14 -5 87 -3 163 l3 139
55 12 c65 14 136 9 168 -12z m1376 17 c46 -11 59 -22 50 -44 -5 -14 -15 -16
-59 -11 -29 4 -69 4 -89 0 -35 -7 -36 -8 -36 -52 l0 -46 76 3 c64 3 79 1 88
-13 5 -9 7 -20 4 -24 -4 -3 -44 -11 -90 -16 l-83 -11 0 -48 0 -49 72 0 c80 0
103 -6 103 -26 0 -24 -41 -36 -111 -32 -55 3 -74 8 -92 26 -21 22 -22 29 -19
175 2 83 4 153 6 155 2 2 25 5 51 7 26 2 50 6 53 9 8 8 30 7 76 -3z m-3324
-37 c83 -33 107 -50 141 -98 55 -74 28 -175 -55 -215 -55 -25 -99 -26 -146 -3
l-35 17 0 145 c0 141 5 180 23 180 5 0 37 -12 72 -26z m2150 -1 c91 -56 107
-119 44 -171 l-36 -30 46 -32 c47 -34 61 -70 28 -70 -10 0 -34 12 -53 26 -40
30 -103 64 -120 64 -7 0 -9 -12 -6 -32 5 -37 -7 -68 -27 -68 -22 0 -26 24 -26
170 0 163 3 170 77 163 26 -3 59 -12 73 -20z m397 -29 c-8 -16 -31 -72 -52
-124 -52 -138 -63 -160 -81 -167 -8 -3 -21 1 -28 8 -19 21 -100 294 -93 314
10 24 39 15 48 -15 4 -14 20 -64 35 -112 14 -49 30 -88 34 -88 5 0 16 24 26
53 9 28 20 54 23 57 4 3 13 24 20 48 6 24 21 52 33 64 18 18 22 18 35 5 13
-13 13 -18 0 -43z m-1715 28 c8 -8 8 -15 -2 -27 -11 -13 -24 -15 -65 -10 -29
4 -55 3 -58 -2 -3 -5 -3 -60 1 -122 8 -119 1 -150 -32 -138 -13 5 -16 21 -16
75 0 38 -3 97 -6 131 l-7 61 -43 0 c-23 0 -49 3 -58 6 -19 7 -21 30 -3 38 22
10 277 0 289 -12z m1963 -2 c24 -20 6 -40 -35 -40 -37 0 -38 -1 -44 -42 -3
-24 -5 -71 -5 -105 l1 -63 38 0 c39 0 64 -18 52 -37 -4 -7 -47 -13 -107 -15
-94 -3 -100 -2 -100 17 0 15 8 21 33 23 l32 3 2 80 c4 158 7 144 -30 147 -36
4 -53 28 -31 44 18 12 177 2 194 -12z m297 -3 c5 -28 -7 -67 -21 -67 -6 0 -15
9 -21 20 -25 46 -103 0 -140 -82 -25 -55 -25 -83 0 -108 25 -25 67 -25 105 0
57 38 94 19 48 -25 -33 -30 -93 -49 -132 -40 -15 3 -40 17 -56 31 -27 23 -30
32 -30 83 1 50 5 63 44 118 47 68 88 92 157 92 36 1 42 -2 46 -22z m-2640
-110 c14 -65 31 -131 36 -147 8 -21 7 -31 -2 -39 -18 -15 -36 1 -51 45 -15 43
-27 49 -90 40 -37 -6 -45 -12 -61 -46 -20 -42 -41 -57 -57 -41 -10 10 20 109
39 126 6 6 33 44 60 85 88 134 90 134 126 -23z m628 101 c5 -13 14 -48 20 -78
19 -105 29 -145 40 -174 8 -23 8 -31 -2 -37 -17 -11 -45 17 -53 52 -9 38 -17
42 -79 35 -50 -7 -54 -9 -74 -52 -23 -46 -28 -49 -51 -35 -11 7 -10 17 5 57
28 73 153 254 176 254 5 0 13 -10 18 -22z m539 11 c17 -6 31 -17 31 -25 0 -11
-16 -14 -67 -14 -74 -1 -107 -16 -101 -47 2 -14 16 -20 56 -24 99 -13 132 -40
132 -109 0 -40 -28 -66 -91 -84 -65 -20 -90 -20 -134 -2 -44 19 -63 52 -41 74
14 14 18 13 41 -12 24 -26 31 -28 77 -23 63 8 98 30 98 61 0 27 -16 36 -80 43
-104 11 -140 50 -99 107 44 60 107 80 178 55z m2036 -9 c26 -22 1 -34 -64 -31
-49 2 -63 -1 -80 -18 -29 -29 -17 -51 28 -51 120 0 188 -80 131 -153 -59 -75
-259 -68 -260 10 0 31 21 34 53 7 34 -29 86 -31 137 -6 68 34 48 78 -40 88
-72 8 -120 35 -120 66 0 28 41 78 78 95 33 15 115 11 137 -7z"/>
<path d="M3425 2928 c-3 -13 -5 -66 -3 -118 l3 -95 57 -3 c51 -3 61 0 83 22
18 18 25 36 25 64 0 52 -28 85 -101 122 l-59 30 -5 -22z"/>
<path d="M5527 2951 c-2 -5 -1 -91 2 -117 1 -11 68 -4 104 11 30 13 20 68 -16
87 -29 15 -86 28 -90 19z"/>
<path d="M3831 2857 c-28 -45 -27 -49 12 -45 29 3 32 6 29 33 -5 46 -17 50
-41 12z"/>
<path d="M4479 2853 c-7 -16 -15 -31 -17 -35 -5 -10 55 -10 61 0 9 13 -5 62
-18 62 -6 0 -18 -12 -26 -27z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
